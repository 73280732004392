import React, { useState, useEffect } from "react";
import "./Mint.css";
import AnimatedPage from "../../Components/AnimatedPage";
import { nftAbi, nftContractAddress, rpc } from "../../contracts";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";


function Mint() {
  const [array, setArray] = useState([1]);
  const {active, library, account} = useWeb3React();
  const [reflections, setReflections] = useState('0');
  const [mintedNfts, setMinted] = useState();
  const success = document.querySelector('.congrats');

  const clickHandlerMinus = (e) => {
    if (array.length <= 10 && array.length >= 0) {
      const copy = [...array];
      copy.splice(0, 1);
      setArray(copy);
    }
  };
  const clickHandlerPlus = (e) => {
    if (array.length <= 9 && array.length >= 0) {
      setArray((prev) => {
        return [...prev, prev.length + 1];
      });
    }
  };

  async function bnbReflections() {
    const web3 = new Web3(rpc);
    const nftContract = new web3.eth.Contract(nftAbi, nftContractAddress);
    let reflections = await nftContract.methods.aData().call({ from: account });
    reflections = web3.utils.fromWei(reflections[1], "ether");
    setReflections(reflections);
  }

  async function howManyMinted() {
    const web3 = new Web3(rpc);
    const nftContract = new web3.eth.Contract(nftAbi, nftContractAddress);
    const mintedNfts = await nftContract.methods.totalSupply().call()
    setMinted(mintedNfts)
  }

  useEffect(() => {
    howManyMinted()
  })

  useEffect(() => {
    if (active) {
      bnbReflections();
    }
  }, [active]);

  function mintNft() {
    let amount = (array.length * 35000000000000000000).toString()
    console.log(amount)
    if (!active) {
      return window.alert("Connect Wallet First");
    }
    const web3 = new Web3(library);
    const nftContract = new web3.eth.Contract(nftAbi, nftContractAddress);

    nftContract.methods
      .mint(array.length)
      .send({
        from: account,
        to: nftContractAddress,
        value: amount,
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
        success.style.display = "flex";
      });
  }

  async function collectRewards(){
    const web3 = new Web3(library);
    const nftContract = new web3.eth.Contract(nftAbi, nftContractAddress);

    nftContract.methods.claimMintRewards().send(
      {
        from: account,
        to: nftContractAddress,
      })
    .on('error', function(error){
      console.log("on error");
      console.log(error);
     })
    .on('transactionHash', function(transactionHash){
      console.log("on transactionHash");
      console.log(transactionHash);
    });
  }

  return (
      <div className="mint">
        <h1>Mint your USDTRAIN NFT</h1>
        <p>{mintedNfts} / 125 reflective NFTs minted so far</p>
        <p> 35 CORE per NFT </p>
        <div className="row-add">
          <div className="additon-div">
            <div className="calc">
              <p onClick={clickHandlerMinus} className="minus">
                -
              </p>
              <p className="number">{array.length}</p>
              <p onClick={clickHandlerPlus} className="plus">
                +
              </p>
            </div>
          </div>
          {active ? <button onClick={mintNft} className="mint-btn">Mint</button> : <button className="mint-btn">Wallet Not Connected</button>}
        </div>
        <h1 style={{ marginTop: "60px" }}> Collect your rewards </h1>
        <h2 style={{ marginTop: "20px", color:"white" }}>{reflections} CORE to collect</h2>
        {active ? <button onClick={collectRewards} className="mint-btn">Collect Rewards</button> : <button className="mint-btn">Wallet Not Connected</button>}
        <h1 style={{ marginTop: "60px" }}>USDTRAIN NFTs</h1>
        <ul>
          <li>20% reflections to holders </li>
          <li>40% treasury</li>
          <li>20% devfunds+marketing</li>
          <li>20% development</li>
        </ul>
      </div>
  );
}

export default Mint;
