import React, { useEffect, useState } from "react";
import Web3 from "web3";
import AnimatedPage from "../../Components/AnimatedPage";
import "./Account.css";
import { useWeb3React } from "@web3-react/core";
import { rpc, usdtrainContract, usdtrainabi } from "../../contracts";

function Account() {
  const [usdtRainHolding, setUsdtRainHolding] = useState("0");
  const [overallUsdtDistributed, setOverallUsdtDistributed] = useState("");
  const [sellAmount, setSellAmount] = useState("0");
  const [totalDividendDistributed, setTotalDividendDistributed] = useState("0");
  const [dividendHolders, setDividendHolders] = useState(""); //needs database
  const [totalSupply, setTotalSupply] = useState("");
  const [dailyTransactions, setDailyTransactions] = useState("");
  const [rewardTracking, setRewardTracking] = useState("");
  const [burnTracking, setBurnTracking] = useState("");
  const [trackPosition, setTrackPosition] = useState("");

  const { library, account } = useWeb3React();

  async function loadWeb3Data() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(usdtrainabi, usdtrainContract);

    let usdtHolding = await contract.methods.balanceOf(account).call();
    usdtHolding = web3.utils.fromWei(usdtHolding);
    usdtHolding = usdtHolding.split(".")[0];
    setUsdtRainHolding(usdtHolding);

    let usdtDistributed = await contract.methods.getTotalDividendsDistributed().call();
    usdtDistributed = web3.utils.fromWei(usdtDistributed);
    usdtDistributed = usdtDistributed.split(".")[0];
    setOverallUsdtDistributed(usdtDistributed);
  }

  async function claimDividends() {
    const web3 = new Web3(library);
    const contract = new web3.eth.Contract(usdtrainabi, usdtrainContract);

    contract.methods.claim()
      .send({
        from: account,
        to: usdtrainContract,
      })
      .on("error", function (error) {
        console.log("on error");
        console.log(error);
      })
      .on("transactionHash", function (transactionHash) {
        console.log("on transactionHash");
        console.log(transactionHash);
      });
  }

  async function loadWeb3DataWalletles() {
    const web3 = new Web3(rpc);
    const contract = new web3.eth.Contract(usdtrainabi, usdtrainContract);

    // Get Sell Amount
    let sellAmount = await contract.methods.sellAmount().call();
    sellAmount = web3.utils.fromWei(sellAmount);
    sellAmount = sellAmount.split(".")[0];
    setSellAmount(sellAmount);

    // Get Total Dividends Distributed
    let dividends = await contract.methods.getTotalDividendsDistributed().call();
    dividends = web3.utils.fromWei(dividends);
    dividends = dividends.split(".")[0];
    setTotalDividendDistributed(dividends);

    let dividendHolders = await contract.methods.getNumberOfDividendTokenHolders().call();
    setDividendHolders(dividendHolders);

    let totalSupply = await contract.methods.totalSupply().call();
    totalSupply = web3.utils.fromWei(totalSupply);
    totalSupply = totalSupply.split(".")[0];
    setTotalSupply(totalSupply);
  }


  useEffect(() => {
    loadWeb3DataWalletles();
  }, []);

  useEffect(() => {
    if (account) {
      loadWeb3Data();
    }
  }, [account]);

  return (
    <AnimatedPage>
      <div className="dashboard account">
        <div className="row-col">
          <div className="col acc-col">
            <h1>Your Balance</h1>
            <h2>{usdtRainHolding}</h2>
            <p>$USDTRAIN</p>
          </div>
          <div className="col acc-col">
            <h1>Sell Amount</h1>
            <h2>{sellAmount}</h2>
            <p></p>
          </div>
          <div className="col acc-col">
            <h1>Total Dividend Distributed</h1>
            <h2>{totalDividendDistributed} $</h2>
            <p></p>
          </div>
        </div>
        <div className="bot-acc">
          <div className="row-acc">
            <p>Dividend Holders</p>
            <p>{dividendHolders}</p>
          </div>
          <div className="row-acc">
            <p>Total Supply</p>
            <p>{totalSupply}</p>
          </div>
        </div>
        <button className="mint-btn" onClick={claimDividends}> Claim Dividends </button>
      </div>
    </AnimatedPage>
  );
}

export default Account;
