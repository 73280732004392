import React, { useState } from "react";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faArrowUpRightFromSquare,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import connect1 from "../../Assets/connect1.svg";
import connect2 from "../../Assets/connect2.svg";
import connect3 from "../../Assets/connect3.svg";
import connect4 from "../../Assets/connect4.gif";
import { injected, walletConnect } from "../../connectors";
import { useWeb3React } from "@web3-react/core";
function Header({ func }) {
  const [modal, setModal] = useState(false);

  const {activate, active} = useWeb3React();

  async function Connect() {
    try {
      await activate(injected);
      setModal(false)
    } catch (ex) {
      console.log(ex);
    }
  }

  async function WalletConnect() {
    try {
      await activate(walletConnect);
      setModal(false)
    } catch (ex) {
      console.log(ex);
    }
  }

  return (
    <div className="header">
      {/* modal ------------ */}
      <>
        <div
          onClick={() => setModal(false)}
          className={`overlay-modal ${modal ? "open-modal" : ""}`}
        ></div>
        <div className={`modal ${modal ? "open-modal" : ""}`}>
          <div onClick={Connect} className="modal-box">
            <div className="modal-cont">
              <img src={connect1} alt="" />
              <h2>MetaMask</h2>
              <p>Connect to your MetaMask Wallet</p>
            </div>
          </div>
          <div onClick={WalletConnect} className="modal-box">
            <div className="modal-cont">
              <img src={connect2} alt="" />
              <h2>WalletConnect</h2>
              <p>Scan with WalletConnect to connect</p>
            </div>
          </div>
        </div>
      </>
      {/* modal end------------ */}
      <div className="left-header">
        <FontAwesomeIcon
          onClick={() => func(true)}
          icon={faBars}
          className="mobile"
        />
      </div>
      <div className="right-header">
        <div onClick={() => setModal(true)} className="link2">
          {active ? <p>Connected</p> : <p>Connect Wallet</p>}
        </div>
      </div>
    </div>
  );
}

export default Header;
