import React, { useEffect } from "react";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faCircleUser,
  faBolt,
  faCalculator,
  faBook,
  faEye,
  faCartArrowDown,
  faImagePortrait,
} from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faTelegram,
  faTwitter,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo.mp4";
function Sidebar({ open, func }) {
  const clickHandler = (e) => {
    document.querySelector(".active").classList.remove("active");
    e.target.classList.add("active");
    func(false);
  };
  useEffect(() => {
    const path = window.location.pathname;
    switch (path) {
      case "/account":
        document.querySelector(".active").classList.remove("active");
        document.querySelector(".account-link").classList.add("active");
        break;
      case "/mint":
        document.querySelector(".active").classList.remove("active");
        document.querySelector(".mint-link").classList.add("active");
        break;
      case "/view":
        document.querySelector(".active").classList.remove("active");
        document.querySelector(".view-link").classList.add("active");
        break;
      default:
        break;
    }
  });

  return (
    <>
      <div
        onClick={() => func(false)}
        className={`overlay ${open ? "open-overlay" : ""}`}
      ></div>
      <div className={`sidebar ${open ? "open-sidebar" : ""}`}>
        <img src={logo} className={"display logo"} alt="" />
        <div className="top-sidebar">
          <Link
            to={"/"}
            onClick={clickHandler}
            className="row-link active account-link"
          >
            <FontAwesomeIcon icon={faCircleUser} />
            <p>Account</p>
          </Link>
          <Link
            to={"/mint"}
            onClick={clickHandler}
            className="row-link mint-link"
          >
            <FontAwesomeIcon icon={faCartArrowDown} />
            <p>Mint USDTRAIN</p>
          </Link>
          <Link
            to={"/view"}
            onClick={clickHandler}
            className="row-link view-link"
          >
            <FontAwesomeIcon icon={faEye} />
            <p>View your NFTs</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
