import React, { useEffect } from "react";
import "./View.css";
import AnimatedPage from "../../Components/AnimatedPage";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { nftAbi, nftContractAddress, rpc } from "../../contracts";
import data from "./lastMeta.json";

function Mint() {
  const { active, account } = useWeb3React();

  const notConnected = (
    <div>
      <h1>Connect with your wallet to view your purchased NFTs</h1>
      <p>So much more than Just an Nft</p>
    </div>
  );

  useEffect(() => {
    if (active) {
      showNft();
    }
  }, [active]);

  async function showNft() {
    let web3 = new Web3(rpc);
    let nftContract = new web3.eth.Contract(nftAbi, nftContractAddress);
    let tokenAmount = await nftContract.methods.balanceOf(account).call();
    let showNfts = document.querySelector(".showNfts");
    let howMany = document.querySelector(".howMany");
    let h2 = document.createElement("h2");
    h2.setAttribute("class", "h2center");
    let h2c = document.createTextNode("You have " + tokenAmount + " NFTs");
    h2.append(h2c);
    howMany.prepend(h2);

    let i = 0;
    for (i = 0; i < tokenAmount; i++) {
      let currentToken = await nftContract.methods.tokenOfOwnerByIndex(account, i).call();
      let div = document.createElement("div");
      div.setAttribute("class", "item");
      let img = document.createElement("img");
      img.setAttribute("src", 'https://thelabz.mypinata.cloud/ipfs/QmeVLwyCpPiGawQMiA8EByBkiiHo7zfMnHJRpEvfYSmGDX/' + currentToken + '.png');
      div.append(img);
      let fp = document.createElement("p");
      let fpn = document.createTextNode("USDTRAIN " + parseInt(currentToken));
      fp.append(fpn);
      div.append(fp);
      let sp = document.createElement("p");
      let spn = document.createTextNode("Token Id: " + currentToken);
      sp.append(spn);
      div.append(sp);
      showNfts.append(div);
    }
  }

  return (
    <AnimatedPage>
      <div className="view">
        {active ? (
          <div className="howMany">
            <div className="showNfts"></div>
          </div>
        ) : (
          notConnected
        )}
      </div>
    </AnimatedPage>
  );
}

export default Mint;
